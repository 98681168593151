import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default function useNotificationHistory() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()
  const refNotificationHistoryTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: '#',
      sortable: true,
    },
    {
      key: 'company_name',
      label: t('company name'),
      sortable: true,
    },
    {
      key: 'email',
      label: t('email'),
      sortable: true,
    },
    // {
    //   key: 'tenderProfile',
    //   sortable: true,
    // },
    {
      key: 'created_at',
      label: t('Created at'),
      sortable: true,
    },
    {
      key: 'notification_send_at',
      label: t('notification send at'),
      sortable: true,
    },
    { key: 'actions', label: t('actions') },
  ]
  const perPage = ref(10)
  const totalNotifications = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const dateFromFilter = ref(null)
  const dateToFilter = ref(null)
  const clientFilter = ref(null)
  const clientOptions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refNotificationHistoryTable.value ? refNotificationHistoryTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalNotifications.value,
    }
  })

  const refetchData = () => {
    refNotificationHistoryTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, dateToFilter, dateFromFilter, clientFilter], () => {
    refetchData()
  })

  const fetchNotificationHistory = (ctx, callback) => {
    store
      .dispatch('tenders/fetchDailyReportHistory', {
        clientId: clientFilter.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        dateTo: dateToFilter.value,
        dateFrom: dateFromFilter.value,
      })
      .then(response => {
        const {
          data,
          total,
        } = response.data

        callback(data)
        totalNotifications.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: t('Error fetching daily reports history'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchNotificationHistorySingle = (ctx, callback) => {
    store
      .dispatch('tenders/fetchDailyReportHistorySingle')
      .then(response => {
        const {
          data,
          total,
        } = response.data

        callback(data)
        totalNotifications.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: t('Error fetching daily reports history'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchNotificationHistory,
    fetchNotificationHistorySingle,
    tableColumns,
    dateToFilter,
    dateFromFilter,
    clientFilter,
    perPage,
    currentPage,
    totalNotifications,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refNotificationHistoryTable,

    statusFilter,

    clientOptions,
    refetchData,
  }
}
