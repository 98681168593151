<template>

  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Entries') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-datepicker
                id="dateFromFilter"
                v-model="dateFromFilter"
                class="mr-1"
                :placeholder="$t('Date from')"
                reset-button
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
              <b-form-datepicker
                id="dateToFilter"
                v-model="dateToFilter"
                class="mr-1"
                reset-button
                :placeholder="$t('Date to')"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
              <v-select
                id="clientFilter"
                v-model="clientFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clientOptions"
                :reduce="option => option.value"
                class="client-filter-select"
                :placeholder="$t('Select Client')"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refNotificationHistoryTable"
        :items="fetchNotificationHistory"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <template #head(invoiceStatus)>
          <feather-icon
            icon="TrendingUpIcon"
            class="mx-auto"
          />
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          {{ data.value }}
        </template>

        <!-- Column: Client -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.client.name }}
            </span>
            <small class="text-muted">{{ data.item.client.companyEmail }}</small>
          </b-media>
        </template>

        <!-- Column:  Sent Date -->
        <template #cell(created_at)="data">
          <span class="text-nowrap">
            {{
              new Date(data.value).toLocaleDateString('sl-si', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
              })
            }}
          </span>
        </template>

        <template #cell(notification_send_at)="data">
          <span class="text-nowrap">
            {{
              data.value ?
                new Date(data.value).toLocaleDateString('sl-si', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric'
                })

                : null
            }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-link
              :to="{ name: 'daily-reports-history-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1"
              />
            </b-link>
            <b-tooltip
              :title="$t('Preview Email')"
              placement="left"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t('Showing {from} to {to} of {of} entries',{from: dataMeta.from, to: dataMeta.to , of:dataMeta.of }) }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalNotifications"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BMedia,
  BLink, BPagination, BTooltip, BFormDatepicker,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'

import useNotificationHistory from '@/views/pages/notification-history/useNotificationHistory'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BPagination,
    BTooltip,
    BFormDatepicker,
    vSelect,
    BLink,
  },
  data() {
    return {
      clientOptions: [],
    }
  },
  created() {
    this.$store.dispatch('clients/fetchClientsAll')
      .then(response => {
        this.clientOptions = response.data.map(client => ({
          label: client.name,
          value: client.id,
        }))
      })
      .catch(error => {
        console.log(error)
      })
  },
  setup() {
    const {
      fetchNotificationHistory,
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalNotifications,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      dateFromFilter,
      dateToFilter,
      clientFilter,
      refetchData,
      refNotificationHistoryTable,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useNotificationHistory()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const showNotificationDetails = ref(false)
    const notificationViewData = ref({})

    return {
      showNotificationDetails,
      notificationViewData,
      fetchNotificationHistory,
      fetchClients,
      refNotificationHistoryTable,
      tableColumns,
      perPage,
      currentPage,
      totalNotifications,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      clientFilter,
      dateFromFilter,
      dateToFilter,
      refetchData,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.client-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
